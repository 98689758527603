<template>
    <div class="timer-section">
        <img src="../../../../../../assets/images/seckill-img/timer.png">
        <div class="mols-content">
            <div class="mols-top">
                <span>
<!--                    会场时间-->
                    {{ startTime }}
                </span>
                &nbsp;点场 {{ status }}
            </div>
            <div class="mols-bottom">
                <template v-for="(item,name) in timerObj">
                    <div class="mols-bottom-item">{{item}}</div>
<!--                    如果当前元素不是秒，则加上冒号-->
                    <template v-if="'sec'!==name">:</template>
                </template>
            </div>
        </div>
    </div>
</template>
<!--思路：在created沟子里请求秒杀数据，并初始化好data-->
<!--timerObj使用hook将请求到的时间格式化到一个对象中-->
<script>
    import UseSeckillTimer from "@/hooks/UseSeckillTimer"
    import http from "@/utils/http"

    export default {
        props:{
            //status,timeObj
            timerObj:{
                type:Object,
                require: true
            },
            startTime:{
                type:String,
                require: true
            },
            status:{
                type:String,
                require:true
            }
        },
    }
</script>
<style lang="scss" scoped>

    .timer-section{

        width: 234px;
        height: 280px;
        font-size: 16px;
        color: white;
        position: relative;
        background-color: white;
        img{
            width: 100%;
            height: 100%;
        }
        .mols-content{
            position: absolute;
            //background-color: pink;
            width: 100%;
            height: 30%;
            bottom: 25px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;
            .mols-top{
                span{
                    font-weight: bold;
                }
            }
            .mols-bottom{
                display: flex;
                align-items: center;
                justify-content: space-around;
                font-weight: bold;
                font-size: 18px;
                color: white;
                .mols-bottom-item{
                    box-sizing: border-box;
                    padding: 6px;
                    width: 33px;
                    height: 33px;
                    background-color: black;
                    margin: 0 5px;
                    text-align: center;
                    line-height: 21px;
                }
            }
        }
    }

</style>