import dayjs from "dayjs"
import customParseFormat from 'dayjs/plugin/customParseFormat'
import timezone from 'dayjs/plugin/timezone'

// 初始化dayjs对象
const dayjsInitial=(dayjsObj)=>{
    dayjsObj.extend(customParseFormat)
    dayjsObj.extend(timezone)
    dayjsObj.tz.setDefault("Asia/Shanghai")
}
dayjsInitial(dayjs)

// 将时间转换为时间戳
// timer格式:"HH:mm:ss"
const transTimeStamp=(timer)=>{
    let timerArr=timer.split(":")
    timerArr=timerArr.map(i=>{
       return  parseInt(i)
    })
    let timeStamp=timerArr[0]*3600+timerArr[1]*60+timerArr[2]
    return timeStamp
}
// 将时间戳转换回时间对象{hh,mm,ss}
const transTime=(timeStamp)=>{
    let sec=Math.floor(timeStamp%3600%60)
    let min=Math.floor((timeStamp%3600-sec)/60)
    let hour=Math.floor(timeStamp/3600)
    return {
        hour:hour,
        min:min,
        sec:sec
    }


}



// 返回秒杀状态的函数   seckillSection组件专用
export function getOriginalTime(startTime,endTime){
    let status={
        WILL_END:"距结束",
        WLL_START:"距开始",
        ALREADY_END:"以结束"
    }
    let RetObj= {
            hour:"00",
            min:"00",
            sec:"00"
            }//初始化返回对象

    let time_hms=transTimeStamp(dayjs()
        .month(0)
        .year(0)
        .format("HH:mm:ss")
    )

    if(time_hms-startTime>0 && time_hms-endTime>0){
        return [status.ALREADY_END,RetObj]
    }else if(time_hms-startTime>0 && time_hms-endTime<0){
        // 得到结束时间的倒计时
        RetObj=transTime(endTime-time_hms)
        return [status.WILL_END,RetObj]
    }else if(time_hms-startTime<0){
        //得到开始时间的倒计时
        RetObj=transTime(startTime-time_hms)
        return [status.WLL_START,RetObj]
    }
}




// Written by Mols!