<template>
<!--    <div class="seckill-wrap" >-->
<!--        <div class="seckill-content" @click="clickProps($event)">-->
<!--            <vue-seamless-scroll-->
<!--                :data="listData"-->
<!--                :class-option="optionLeft"-->
<!--                class="seamless-warp2"-->
<!--            >-->
<!--&lt;!&ndash;                具名插槽 v-slot:name&ndash;&gt;-->
<!--                <template #left-switch >-->
<!--                    <div class="seckill-switch" style="left: -23px"><i class="el-icon-arrow-left"></i></div>-->
<!--                </template>-->

<!--                <ul class="item" :style="{ width: 250 * listData.length + 'px' }" >-->
<!--                    <li v-for="(item, index) in listData" :key="index" style="border:none" >-->
<!--                        <div class="seckill-goods" :data-obj="JSON.stringify(item)">-->
<!--                            <div class="seckill-goods-img"  :data-obj="JSON.stringify(item)">-->
<!--                                <img :src="goodsImg(item.goods_image)"  @error="imageError(index)"  :data-obj="JSON.stringify(item)"/>-->
<!--                            </div>-->
<!--                            <p-->
<!--                                style="color: grey;height: 35px;"-->
<!--                            >-->
<!--                                {{ item.goods_name }}-->
<!--                            </p>-->
<!--                            <div class="n0">-->
<!--                                <div class="n1" >-->
<!--                                    &lt;!&ndash; 此处写秒杀的价格 &ndash;&gt;-->
<!--                                    ￥-->
<!--                                    {{  item.seckill_price}}-->
<!--                                </div>-->
<!--                                <div class="n2"></div>-->
<!--                                &lt;!&ndash;                                                此处写展示的价格&ndash;&gt;-->
<!--                                <div class="n3" >￥{{item.price}} </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </li>-->
<!--                </ul>-->

<!--                <template #right-switch >-->
<!--                    <div class="seckill-switch" style="right: -23px"><i class="el-icon-arrow-right"></i></div>-->
<!--                </template>-->
<!--            </vue-seamless-scroll>-->
<!--        </div>-->
<!--    </div>-->
    <el-carousel indicator-position="none" :autoplay="false">
        <el-carousel-item v-for="item in showListData">
            <ul class="seckill-slide">
                <li v-for="(childItem, index) in item" :key="index" @click="clickProps($event)" >

                        <div class="mols-seckill-img"  :data-obj="JSON.stringify(childItem)">
                            <img :src="goodsImg(childItem.goods_image)"  @error="imageError(index)"  :data-obj="JSON.stringify(childItem)"/>
                        </div>
                        <p>
                            {{ childItem.goods_name }}
                        </p>
                        <div class="n0">
                            <div class="n1">￥{{  childItem.seckill_price}}</div>
                            <div class="n2"></div>
                            <!--                                                此处写展示的价格-->
                            <div class="n3">￥{{childItem.price}}</div>
                        </div>

                </li>
            </ul>
        </el-carousel-item>
    </el-carousel>
</template>

<script>
    import UseGrouping from "@/hooks/UseGrouping"
    export default {
        name: "seckillSectionRight",
        props:{
          listData:{
              type:Array,
              default:()=>([])
          }

        },
        created(){
            // 请求到的数组进行分组，分组完后进行ul遍历
            this.molsListData=UseGrouping(5,this.listData)

        },
        data:()=>({
            molsListData:[]
        }),
        computed:{
            optionLeft() {
                return {
                    direction: 2,
                    limitMoveNum: 1,
                    navigation:true,
                    switchOffset:2,
                    switchSingleStep:454
                }
            },
            showListData(){
                this.molsListData=UseGrouping(5,this.listData)
                return this.molsListData
            }
        },
        methods:{
            /* 秒杀图片处理 */
            goodsImg(imgStr) {
                let imgs = imgStr.split(',');
                return imgs[0] ? this.$util.img(imgs[0], { size: 'mid' }) : this.defaultGoodsImage;
            },
            clickProps(e){
                if(!e.target.dataset.obj){ return }
                let item = JSON.parse(e.target.dataset.obj);
                this.$router.pushToTab('/promotion/seckill-' + item.id)
            },
            imageError(index) {
                this.listData[index].sku_image = this.defaultGoodsImage
            }
        }
    }
</script>

<style scoped lang="scss">//我的作用域污染不了，但可以污染儿子的
    $w:(1200px - 234)/5;
    .seckill-slide *{
        box-sizing: border-box;
    }
    ul{
        background-color: white;
        //padding: 20px;
        //li固定好了，里面的内容飘了
        li{
            width: $w;
            height: 280px;
            float: left;
            background-color: white;
            padding: ($w - 150)/2;
            //display: flex;

            .mols-seckill-img{
                width: 150px;
                height:150px;
                cursor: pointer;
            }
            p{
                color: grey;
                //margin: 16px 0 12px 0;
                text-overflow: ellipsis;
                white-space:nowrap;
                line-height: 42px;
                width: 150px;
                height: 42px;
                overflow: hidden;
            }
        }
    }
    .el-carousel{
        width: 970px;
        height: 280px;
        overflow: hidden;
        //background-color: lightgrey;
    }
    //秒杀的价格样式
    .n0 {
    width: 150px;
    height: 26px;
    border: 1px solid red;
    position: relative;
        background-color: white;
    .n1 {
        font-size: 16px;
        font-weight: bold;
        color: white;
        text-align: center;
        line-height: 24px;
        width: 50%;
        height: 100%;
        background: red;
        position: absolute;
        top: 0px;
        left: 0;
    }

    .n2 {
        position: absolute;
        top: 0;
        left: 70px;
        border-top: 24px solid white;
        border-right: 12px solid red;
        transform: rotateY(180deg);
        background-color: white;
    }

    .n3 {
        position: absolute;
        right: 0;
        width: 65px;
        height: 100%;
        font-size: 14px;
        text-align: center;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #999999;
        line-height: 26px;
        text-decoration: line-through;
        background-color: white;
    }
}




    // 限时秒杀
    //.seckill-wrap {
    //    box-sizing:border-box;
    //    height: 280px;
    //    //width: $width;
    //    //由于秒杀大小有限制，所以只能写死
    //    width: 967px;
    //    background-color: #ffffff;
    //    //margin: 20px auto;
    //    border: 1px solid #e9e9e9;
    //    position: relative;
    //}
    //.seckill-content {
    //    box-sizing: border-box;
    //    height: 100%;
    //    padding: 25px;
    //    //padding: 20px 20px 0;
    //}
    //.seckill-switch{
    //    position: absolute;
    //    top: 90px;
    //    width: 24px;
    //    height:42px;
    //    font-size: 40px;
    //    background-color: lightgrey;
    //    display: flex;
    //    align-items: center;
    //    justify-content: center;
    //    cursor: pointer;
    //    overflow: hidden;
    //    //transition:font-size 2s ease,font-weight 2s ease;
    //    //:hover{
    //    //    color:white;
    //    //    font-size: 45px;
    //    //    font-weight: bold;
    //    //    background-color: pink;
    //    //}
    //    color: white;
    //}
    //.seamless-warp2 {
    //    overflow: hidden;
    //
    //    ul.item {
    //        li {
    //            float: left;
    //            width: 227px;
    //            padding: 0 25px;
    //            box-sizing: border-box;
    //            border-right: 1px solid #f1f1f1;
    //
    //            .seckill-goods {
    //                cursor: pointer;
    //
    //                .seckill-goods-img {
    //                    width: 100%;
    //                    height: 200px;
    //                }
    //                img {
    //                    object-fit: cover;
    //                }
    //
    //                > p {
    //                    line-height: 24px;
    //                    display: -webkit-box;
    //                    -webkit-box-orient: vertical;
    //                    -webkit-line-clamp: 2;
    //                    overflow: hidden;
    //                    height: 50px;
    //                }
    //
    //                //.seckill-price-wrap {
    //                //	display: flex;
    //                //	align-items: flex-end;
    //                //	height: 24px;
    //                //	line-height: 24px;
    //                //	margin-top: 10px;
    //                //
    //                //	p span {
    //                //		font-size: 24px;
    //                //	}
    //                //
    //                //	.primary-price {
    //                //		text-decoration: line-through;
    //                //		color: #838383;
    //                //		margin-left: 10px;
    //                //	}
    //                //}
    //
    //                }
    //            }
    //        }
    //    }
    //}
</style>