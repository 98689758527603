// 对看了又看模块进行分组
/**
 * @param layer 要分几层
 * @param list  被分的数组
 * @constructor
 */
export default function UseGrouping(layer,list){
    let RetArray=[]
    let index = -1;
    list.forEach((item,i)=>{
        if(i%layer === 0){
            index++;
            RetArray[index] = [];
        }
        RetArray[index].push(item)
    })
    // !!!!!!!!!!!!!
    // for (let k=layer-1;k<list.length;k+=layer){
    //     let InnerArray=[]
    //     for (let v=0;v<layer;v++){
    //         InnerArray.push(list[k-v])
    //     }
    //     RetArray.push(InnerArray.reverse())
    // }
    return RetArray
}
