<template>
    <div v-if="data" style="display: flex;justify-content: center;">
        <seckill-section-left
            :timer-obj="diffTimerObj"
            :start-time="seckillStartTimeShow"
            :status="status"
        ></seckill-section-left>
        <seckill-section-right :list-data="goodsList" ></seckill-section-right>
    </div>
</template>

<script>
    import SeckillSectionLeft
        from "@/components/seckillSection/src/components/seckillSectionLeft/src/seckillSectionLeft"
    import SeckillSectionRight
        from "@/components/seckillSection/src/components/seckillSectionRight/src/seckillSectionRight"
    import http from "@/utils/http"
    import {getOriginalTime} from "@/hooks/UseSeckillTimer"

    export default {
        name: "seckillSection",
        components: { SeckillSectionRight, SeckillSectionLeft },
        data:()=>({
            data:null,
            goodsList:[],
            seckillEndTimeShow:"",
            seckillStartTimeShow:"",
            TimerArr:[],
            diffTimerObj:{},
            status:"",
        }),
        methods:{
            load(){
                http({
                    url: "/seckill/api/seckill/last",
                    data: {}
                }).then(res=>{
                    this.data = res.data;
                    this.initTimer()
                    this.initGoodsData()
                })
            },
            initTimer(){
              setInterval(e=>{
                  this.seckillStatusInit()
                  // 将时间对象赋值和初始化秒杀活动的状态
                  this.status=this.TimerArr[0]
                  this.diffTimerObj=this.TimerArr[1]
                  for(let item in this.diffTimerObj){
                      if(this.diffTimerObj[item]<10){
                          this.diffTimerObj[item]=`0${this.diffTimerObj[item]}`
                      }
                  }
              },1000)
              this.dealStartTimer()
            },
            seckillStatusInit(){
                this.TimerArr=getOriginalTime(this.data.seckill_start_time,this.data.seckill_end_time)
            },
            // 对活动开始时间做格式化处理
            dealStartTimer(){
                let startTimeArr
                this.seckillStartTimeShow=this.data.seckill_start_time_show
                startTimeArr=this.seckillStartTimeShow.split(":")
                this.seckillStartTimeShow=`${startTimeArr[0]}:${startTimeArr[1]}`
            },
            initGoodsData(){
                this.goodsList=this.data.goods_list
                console.log(this.goodsList)
            }
        },
        created() {
            this.load()
        }

    }
</script>



<style scoped lang="scss">
</style>