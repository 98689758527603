<template>
  <div>
    <!-- 首页固定区 -->
    <div class="index-wrap" :style="{ background: backgroundColor }">


      <el-carousel height="448px" v-loading="loadingAd" @change="handleChange">
        <el-carousel-item v-for="item in adList" :key="item.adv_id">
          <div :style="`background:url(${$img(item.adv_image)}) no-repeat center center;width:100%;height:100%`"
            @click="$router.pushToTab(item.adv_url.url)">
          </div>
        </el-carousel-item>

        <div style="width: 1200px;margin: 0 auto;position: relative;">


          <!--start-->
          <div class="news" style="">
            <div class="login-wrap">
              <div class="avtar">
                <router-link to="/member/info">
                  <img v-if="member.headimg" :src="$img(member.headimg)" @error="member.headimg = defaultHeadImage" />
                  <img v-else :src="$img(defaultHeadImage)" />
                </router-link>
              </div>

              <template v-if="!member">
                <p id="he">Hi,欢迎登陆纳福商城</p>
                <router-link to="/login">
                  <el-button type="warning" size="medium" style="text-align: center">登录</el-button>
                </router-link>
              </template>

              <div class="memeber-name" v-else>{{ member.nickname }}</div>
            </div>
            <!-- 商城快讯 -->
            <div class="notice-wrap">
              <div class="notice">
                <div class="notice-name">最新资讯</div>
              </div>
              <div v-for="item in noticeList" :key="item.id" class="item">
                <span style="color: #ff6900">[热点]</span>
                <router-link :to="'/cms/notice-' + item.id" target="_blank" tag="a">
                  {{ item.title }}
                </router-link>
              </div>
            </div>
            <!-- 商城服务 -->
            <div class="server-wrap">
              <el-button @click="e => storeService()" type="danger" size="small">商家加盟</el-button>
              <el-button @click="e => companyCenter()" type="warning" size="small">企业入驻</el-button>

            </div>
          </div>
          <!--end-->


        </div>


      </el-carousel>


    </div>

    <div v-if="floors.length > 0" class="floor-nav" id="floorNav"
      :style="{ zIndex: 999999, right: floorOffset + 'px', ...floorStyle }">
      <ul class="floor-nav-list">
        <li v-for="(floor, index) in floors" :class="['floor-item', index == curFloorIndex && 'active']"
          @click="e => switchFloor(index)" class="nav-list-item">
          {{ floor.title }}
        </li>
        <li class="return-top" @click="goTop">
          <div style="line-height: 1;">
            <i class="el-icon-caret-top"></i>
          </div>
          <em class="tab-text">顶部</em>
        </li>
      </ul>
      <!--                        {{curFloorIndex}}-->
    </div>

    <div class="content">


      <!-- 领券中心 -->
      <div v-if="addonIsExit.coupon == 1 && couponList.length > 0 && (city.id == 0 || !city)">
        <div class="coupon">
          <div class="coupon-title">
            <p class="coupon-font">领券中心</p>
            <p class="coupon-en">coupon</p>
            <p class="coupon-more" @click="$router.push('/coupon')">
              <span>更多</span>
              <i class="iconfont iconarrow-right"></i>
            </p>
          </div>
          <ul class="coupon-list">
            <li v-for="(item, index) in couponList" :key="index">
              <p class="coupon-price ns-text-color">
                ￥
                <span>{{ item.money }}</span>
              </p>
              <p class="coupon-term">满{{ item.at_least }}可用</p>
              <p class="coupon-receive ns-text-color" @click="couponTap(item, index)">
                <span v-if="item.useState == 0">立即领取</span>
                <span v-else>去使用</span>
                <i class="iconfont iconarrow-right"></i>
              </p>
            </li>
          </ul>
        </div>
      </div>


      <div style="width: 1200px;margin: 0 auto;margin-top: 20px;">
        <div style="justify-content: space-between;display: flex;font-size:0;">
          <div v-for="item in magics"
            :style="`height: 200px; width: calc(100% / ${magics.length} - 5px);display:inline-block;`">
            <el-image :src="$img(item.adv_image)" style="width: 100%;height: 100%"
              @click="$router.pushToTab(item.adv_url.url)"></el-image>
          </div>
        </div>
      </div>

      <div style="width: 100%;height: 20px"></div>

      <!-- 限时秒杀 -->
      <seckill-section />



      <!-- 楼层区 -->
      <div>
        <div class="floor">
          <div v-for="(item, index) in floorList" :key="index" class="floor_item clearfix" :id="`floor${index + 1}`">
            <floor-style-1 v-if="item.block_name == 'floor-style-1'" :data="item" />
            <floor-style-2 v-if="item.block_name == 'floor-style-2'" :data="item" />
            <floor-style-3 v-if="item.block_name == 'floor-style-3'" :data="item" />
            <floor-style-4 v-if="item.block_name == 'floor-style-4'" :data="item" />
          </div>
        </div>
      </div>
      <!-- 分站商品列表 -->
      <div>
        <div class="more">
          <router-link to="/list">
            <span>更多</span>
            <i class="iconfont iconarrow-right"></i>
          </router-link>
        </div>
        <div class="goods-info" v-if="goodsList.length">
          <div class="item" v-for="(item, index) in goodsList" :key="item.goods_id"
            @click="$router.pushToTab({ path: '/sku-' + item.sku_id })">
            <img class="img-wrap" :src="$img(item.sku_image, { size: 'mid' })" @error="imageError(index)" />
            <div class="price-wrap">
              <div class="price">
                <p>￥</p>
                {{ item.discount_price }}
              </div>
              <div class="market-price">￥{{ item.market_price }}</div>
            </div>
            <div class="goods-name">{{ item.goods_name }}</div>
            <div class="sale-num">
              <p>{{ item.sale_num || 0 }}</p>人付款
            </div>
            <div class="saling">
              <div class="free-shipping" v-if="item.is_free_shipping == 1">包邮</div>
              <div class="promotion-type" v-if="item.promotion_type == 1">限时折扣</div>
            </div>
          </div>
        </div>
      </div>

      <!-- 浮层区 -->
      <div class="floatLayer-wrap" v-if="floatLayer.is_show && city.id == 0">
        <div class="floatLayer">
          <div class="img-wrap">
            <img :src="$img(floatLayer.img_url)" @click="$router.pushToTab(floatLayer.link.url)" />
          </div>
          <i class="el-icon-circle-close" @click="closeFloat"></i>
        </div>
      </div>

      <!-- 悬浮搜索 -->
      <!--div class="fixed-box" :style="{ display: isShow ? 'block' : 'none' }">
        <div class="header-search">
          <el-row>
            <el-col :span="6">
              <router-link to="/" class="logo-wrap"><img :src="$img(siteInfo.logo)"/></router-link>
            </el-col>
            <el-col :span="13">
              <div class="in-sousuo">
                <div class="sousuo-box">
                  <el-dropdown @command="handleCommand" trigger="click">
										<span class="el-dropdown-link">
											{{ searchTypeText }}
											<i class="el-icon-arrow-down"></i>
										</span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="goods">商品</el-dropdown-item>
                      <el-dropdown-item command="shop">店铺</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <input type="text" :placeholder="defaultSearchWords" v-model="keyword"
                         @keyup.enter="search" maxlength="50"/>
                  <el-button type="primary" size="small" @click="search">搜索</el-button>
                </div>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="cart-wrap">
                <router-link class="cart" to="/cart">
                  <span>我的购物车</span>
                  <el-badge v-if="cartCount" :value="cartCount" type="primary"><i
                      class="iconfont icongouwuche"></i></el-badge>
                  <i v-else class="iconfont icongouwuche"></i>
                </router-link>
              </div>
            </el-col>
          </el-row>
        </div>
      </div-->
    </div>
  </div>
</template>

<script>
import floorStyle1 from "./components/floor-style-1.vue"
import floorStyle2 from "./components/floor-style-2.vue"
import floorStyle3 from "./components/floor-style-3.vue"
import floorStyle4 from "./components/floor-style-4.vue"

import index from "./_index/index.js"
import seckillSection from "@/components/seckillSection/src/seckillSection"

export default {
  name: "index",
  components: {
    seckillSection,
    floorStyle1,
    floorStyle2,
    floorStyle3,
    floorStyle4
  },
  mixins: [index],
  data: () => {

    return {
      floors: [],
      curFloorIndex: -1,
      floorOffset: 0,
      floorStyle: {
        position: "absolute",
        top: "650px",
      }
    }

  },
  methods: {
    storeService() {
      // window.location.href = "http://mall.nafu365.com/shop/login/register.html"

      window.open(this.shopApplyUrl)
    },
    companyCenter() {
      // this.$router.replace("/cms/help-10")
      window.open('https://mall.nafu365.com/company')
    },
    switchFloor(index) {
      this.curFloorIndex = index
      location.href = "#floor" + (index + 1)
    },
    onWindowResize() {
      this.floorOffset = this.getFloorBarOffset()
    },
    goTop() {
      document.documentElement.scrollTop = 0
    },
    getFloorBarOffset() {
      let windowWidth = document.body.clientWidth
      let contentWidth = 1200
      let floor = 60
      return (windowWidth - contentWidth) / 2 - floor - 40
    },
    getCurFloor() {
      let len = this.floors.length
      this.curFloorIndex = -1
      for (let i = 1; i <= len; i++) {
        let tmp = document.getElementById("floor" + i).getClientRects()[0];
        if (tmp['top'] < 100 && tmp['bottom'] >= 0) {
          this.curFloorIndex = i - 1
          break;
        }

      }
    }

  },
  mounted() {
    window.onresize = this.onWindowResize
    window.onload = this.onWindowResize
    window.onscroll = () => {
      let osTop = document.documentElement.scrollTop || document.body.scrollTop
      if (osTop < 650) {
        this.floorStyle = {
          position: "absolute",
          top: "650px",
          right: `${this.getFloorBarOffset()}px`
        }
      } else {

        this.getCurFloor(osTop)
        this.floorStyle = {
          position: "fixed",
          right: `${this.getFloorBarOffset()}px`
        }
      }
    }
  }

}



</script>



<style lang="scss" scoped>
@import './_index/index.scss';
</style>

<style lang="scss" scoped>
.floor-nav {
  width: 48px;
  background-color: white;
  position: fixed;
  top: 172px;
  right: 40px;
}

.floor-nav-list {
  width: 48px;
  display: inline-block;
  text-align: center;
  background-color: white;
  padding: 0;

  // line-height: 20px;
}

.floor-nav .floor-nav-list .nav-list-item {
  display: inline-block;
  width: 48px;
  vertical-align: middle;
  align-self: center;
  text-align: center;
  border-bottom: 1px solid #f8f8f8;
  cursor: pointer;
  font-size: 12px;
  letter-spacing: 3px;
  height: 48px;
  line-height: 20px;
  // padding: 0 2px;
  padding-left: 4px;
  padding-top: 4px;
  box-sizing: border-box;
  overflow: hidden;
}

.nav-list-item {
  // padding: 10px;
}

.floor-nav .floor-nav-list .nav-list-item.active,
.floor-nav .floor-nav-list .nav-list-item:hover {
  color: white;
  background-color: red;
  width: 48px;
  height: 48px;
}

.return-top {
  width: 48px;
  height: 48px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  padding: 2px 0;

  .el-icon-caret-top {
    line-height: 1;
  }

  .tab-text {
    padding: 0;
    line-height: 1;
  }
}

.return-top:hover {
  background-color: red;
  color: #fff;
}

.tab-text {
  font-size: 12px;
  padding: 10px;
  letter-spacing: 3px;
}

#he {
  font-size: 16px;
  text-align: center;
}


.count-down {
  span {
    display: inline-block;
    width: 22px;
    height: 22px;
    line-height: 22px;
    text-align: center;
    background: #383838;
    color: #ffffff;
    border-radius: 2px;
  }
}

.more {
  width: 1200px;
  margin: auto;
  display: flex;
  justify-content: flex-end;

  a {
    color: #ff547b
  }
}

.goods-info {
  width: 1200px;
  margin: 5px auto 40px;
  display: flex;
  flex-wrap: wrap;

  .item {
    width: 190px;
    margin: 10px 20px 0 0;
    border: 1px solid #eeeeee;
    padding: 10px;
    position: relative;
    word-wrap: break-word;

    &:nth-child(5 n) {
      margin-right: initial !important;
    }

    &:hover {
      border: 1px solid $base-color;
    }

    .img-wrap {
      width: 198px;
      height: 198px;
      cursor: pointer;
    }

    .goods-name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;

      &:hover {
        color: $base-color;
      }
    }

    .price-wrap {
      display: flex;
      align-items: center;

      .price {
        display: flex;
        color: $base-color;
        font-size: $ns-font-size-lg;

        p {
          font-size: $ns-font-size-base;
          display: flex;
          align-items: flex-end;
        }
      }

      .market-price {
        color: #838383;
        text-decoration: line-through;
        margin-left: 10px;
      }
    }

    .sale-num {
      display: flex;
      color: #838383;

      p {
        color: #4759a8;
      }
    }

    .saling {
      display: flex;
      font-size: $ns-font-size-sm;
      line-height: 1;

      .free-shipping {
        background: $base-color;
        color: #ffffff;
        padding: 3px 4px;
        border-radius: 2px;
        margin-right: 5px;
      }

      .promotion-type {
        color: $base-color;
        border: 1px solid $base-color;
        display: flex;
        align-items: center;
        padding: 1px;
      }
    }
  }
}
</style>
